/**
 * Describes the procedure for the user study
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-15 (last updated 2024-10-02)
 * Notes: /
 */

import React, { useState } from 'react';

import { Button, Container, Row, Nav, Tab, Card, Col, Alert, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import { UserInformation } from '../components/auth_mgmt/auth_handling';

import { useLocation } from 'react-router-dom';

/**
 * Provides a classification of the study
 * @returns 
 */
export const Classification = () => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={10}>
          <Tab.Container id="manual-tab" defaultActiveKey="objective">
            <Row>
              {/* Navigation Tabs */}
              <Col md={3} className="mb-3">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="objective" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                      <i className="bi bi-info-circle"></i> Objective
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="task" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                      <i className="bi bi-briefcase"></i> Your Task
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="outcome" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                      <i className="bi bi-check2-circle"></i> Outcome
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="usage" style={{ border: '1px solid', borderRadius: '5px', marginBottom: '10px' }}>
                      <i className="bi bi-gear"></i> Usage
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              {/* Tab Content */}
              <Col md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="objective">
                    <Card className="shadow-sm">
                      <Card.Body>
                        <p>
                          The objective of this research is to introduce a <b>semantic representatio</b> and a <b>Use Case Mapper tool</b> to identify similarities across use cases, 
                          enabling the creation of a <b>reference platform economy model</b> for AM Solutions (AMS), which helps stakeholders design, map, 
                          and optimize scalable and interoperable ecosystems.
                        </p>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  <Tab.Pane eventKey="task">
                    <Card className="shadow-sm">
                      <Card.Body>
                        <p>
                          In this study, you will <b>model use cases</b> related to AM using both the UCM Co-Pilot and manual mode. 
                          This helps us explore AM requirements; also, you are asked to <b>answer a few questions</b> (semi-structured interview) 
                          as well as run through a quick <b>survey</b> for us to learn about the usability of the UCM tool.
                        </p>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  <Tab.Pane eventKey="outcome">
                    <Card className="shadow-sm">
                      <Card.Body>
                        <p>
                          A unified <b>semantic model</b> for AMS that serves as a <b>blueprint architecture</b>, 
                          alongside a knowledge-base-driven Use Case Mapper tool. 
                          This tool facilitates the design, mapping, and optimization of AMS ecosystems by <b>identifying 
                          recurrent requirements</b>, while suggesting solutions through an association guide based on <b>common patterns</b>.
                        </p>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  <Tab.Pane eventKey="usage">
                    <Card className="shadow-sm">
                      <Card.Body>
                        <p>
                          AMS <b>necessitate</b> the integration of hardware, software and ecosystem components. 
                          The <b>identification</b>, <b>choreography</b> and <b>development</b> of the elements, such as scenarios, actors, data, services 
                          in the AMS deployment landscape lacks thorough exploration.
                          <br /><br />
                          The developed semantic models and UCM tool form the foundation for <b>proposing a reference model</b>, 
                          enabling experts to model, analyze, and better understand the requirements for AM solution engineering.
                        </p>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export const Tasks = ( { handleStartStudy } ) => {

  const [activeTab, setActiveTab] = useState('step1'); // State to keep track of active tab

  // Function to go to the next tab when the user clicks on 'Noted' button
  const nextTab = () => {
    switch (activeTab) {
      case 'step1':
        setActiveTab('step2');
        break;
      case 'step2':
        setActiveTab('step3');
        break;
      case 'step3':
        setActiveTab('step4');
        break;
      case 'step4':
        setActiveTab('step5');
        break;
      case 'step5':
        handleStartStudy();
        break;
      default:
        break;
    }
  };

  const previousTab = () => {
    switch (activeTab) {
      case 'step2':
        setActiveTab('step1');
        break;
      case 'step3':
        setActiveTab('step2');
        break;
      case 'step4':
        setActiveTab('step3');
        break;
      case 'step5':
        setActiveTab('step4');
        break;
      default:
        break;
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center mb-4">
        <Col md={12}>
          <Tab.Container id="manual-tab" activeKey={activeTab}>
            <Row>
              {/* Navigation Tabs */}
              <Col md={12}>
                <Nav variant="tabs" className="mb-4 justify-content-center">
                  <Nav.Item>
                    <Nav.Link eventKey="step1" style={{ borderRadius: '5px' }} disabled>
                      <i className="bi bi-box"></i> SAV (1)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="step2" style={{ borderRadius: '5px' }} disabled>
                      <i className="bi bi-box"></i> Custom (2)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="step3" style={{ borderRadius: '5px' }} disabled>
                      <i className="bi bi-box"></i> Co-Pilot (3)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="step4" style={{ borderRadius: '5px' }} disabled>
                      <i className="bi bi-question-circle"></i> Interview (4)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="step5" style={{ borderRadius: '5px' }} disabled>
                      <i className="bi bi-clipboard"></i> Survey (5)
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              {/* Tab Content */}
              <Col md={12}>
                <Tab.Content>
                  {/* Step 1 Model with SP with UCM */}
                  <Tab.Pane eventKey="step1">
                    <Card className="shadow-sm mb-4">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col md={1}>
                            <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                          </Col>
                          <Col>
                            <strong>Model a Smart Parking use case using the UCM Co-Pilot</strong>
                            <p className="mb-0">To do this, just provide a prompt to UCM and adapt the suggested elements that meets your understanding of this use case.</p>
                            <p className="mb-0">
                              <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                              <small>You have 10 minutes.</small>
                            </p>
                          </Col>
                          <Col md={2} className="text-end">
                            <Button variant="outline-primary" className="btn-sm" onClick={nextTab}>Noted</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  {/* Step 2 Model any use case manually */}
                  <Tab.Pane eventKey="step2">
                    <Card className="shadow-sm mb-4">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col md={1}>
                            <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                          </Col>
                          <Col>
                            <strong>Model a Use Case of Your Choice in the Manual Mode</strong>
                            <p className="mb-0">
                              Select any use case of your choice in the mobility and transportation domain, 
                              and model it manually without the UCM Co-Pilot assistance.
                            </p>
                            <p className="mb-0">
                              <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                              <small>You have 15 minutes.</small>
                            </p>
                          </Col>
                          <Col md={2} className="text-end">
                            <Button variant="outline-primary" className="btn-sm" onClick={previousTab} style={ { marginRight:'5px' } }>
                              <i className="bi bi-arrow-left"></i>
                            </Button>
                            <Button variant="outline-primary" className="btn-sm" onClick={nextTab}>Noted</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  {/* Step 3 Re-Model with UCM */}
                  <Tab.Pane eventKey="step3">
                    <Card className="shadow-sm mb-4">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col md={1}>
                            <i className="bi bi-box" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                          </Col>
                          <Col>
                            <strong>Revisit your use case and model it from scratch with UCM Co-Pilot</strong>
                            <p className="mb-0">
                              Provide a prompt for UCM to model your previously created use case 
                              from scratch using the Co-Pilot.
                            </p>
                            <p className="mb-0">
                              <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                              <small>You have 10 minutes.</small>
                            </p>
                          </Col>
                          <Col md={2} className="text-end">
                            <Button variant="outline-primary" className="btn-sm" onClick={previousTab} style={ { marginRight:'5px' } }>
                              <i className="bi bi-arrow-left"></i>
                            </Button>
                            <Button variant="outline-primary" className="btn-sm" onClick={nextTab}>Noted</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  {/* Step 4 Content */}
                  <Tab.Pane eventKey="step4">
                    <Card className="shadow-sm mb-4">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col md={1}>
                            <i className="bi bi-question-circle" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                          </Col>
                          <Col>
                            <strong>Step 4: Answer Questions</strong>
                            <p className="mb-0">
                              You get asked a few pre-defined questions tailored to your domain expertise.
                              These are transcripted and analyzed for the study.
                            </p>
                            <p className="mb-0">
                              <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                              <small>You have 15 minutes.</small>
                            </p>
                          </Col>
                          <Col md={2} className="text-end">
                            <Button variant="outline-primary" className="btn-sm" onClick={previousTab} style={ { marginRight:'5px' } }>
                              <i className="bi bi-arrow-left"></i>
                            </Button>
                            <Button variant="outline-primary" className="btn-sm" onClick={nextTab}>Noted</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>

                  {/* Step 5 Content */}
                  <Tab.Pane eventKey="step5">
                    <Card className="shadow-sm mb-4">
                      <Card.Body>
                        <Row className="align-items-center">
                          <Col md={1}>
                            <i className="bi bi-clipboard" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>
                          </Col>
                          <Col>
                            <strong>Step 5: Complete the Survey</strong>
                            <p className="mb-0">
                              Finish by answering a quick survey about your experience with the tool. 
                              This helps us understand the tool's usability.
                            </p>
                            <p className="mb-0">
                              <i className="bi bi-clock" style={{ color: '#007bff', marginRight:'5px' }}></i>
                              <small>You have 5 minutes.</small>
                            </p>
                          </Col>
                          <Col md={2} className="text-end">
                            <Button variant="outline-primary" className="btn-sm" onClick={previousTab} style={ { marginRight:'5px' } }>
                              <i className="bi bi-arrow-left"></i>
                            </Button>
                            <Button variant="outline-primary" className="btn-sm" onClick={handleStartStudy}>Start</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Col>

              {/* Total Time Information */}
              <Col md={12}>
                <Card className="shadow-sm">
                  <Card.Body className="text-center">
                    <Card.Text>
                      <strong>It takes you about 55 minutes to complete the entire study.</strong>
                    </Card.Text>
                    <p>
                      Please complete each step in the time provided. We provide a time indicator and track each step's duration automatically. 
                      This allows us to gather consistent and comparable data.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default function Instructions(props) {

    const [showTasks, setShowTasks] = useState(false);
    const [startStudy, setStartStudy] = useState(false);

    const location = useLocation();
    const { expertData } = location.state || {};

    //console.log(expertData);

    const user = UserInformation();

    const navigate = useNavigate();
    // creator, cockpit

    const navigateParticipate = () => {
        navigate('/Participate');
    };

    const handleShowTasks = () => {
        setShowTasks(!showTasks);
    };

    const handleStartStudy = () => {
        setStartStudy(!startStudy);
    };

    const navigateCreate = () => {
      navigate('/Create', { state: { mode: 'copilot', expertData } });
    };

    return (
        <Container fluid>
            {/* Instructions */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Welcome to the Expert Study 'AM Use Case Modeling'</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                  Look up the few hints below and you are good to go.
                </p>
            </Row>
            {/* Classification */}
            {!showTasks &&
            <Row >
                <h3 align='center' style={{marginTop: '30px', marginBottom: '30px'}}>
                    Research Scope and Classification
                </h3>
                <div style={ { maxWidth: '800px', margin: '0 auto' } }>
                    <Classification />                                   
                </div>  
            </Row>
            }
            {/* Show the tasks */}
            {!startStudy &&
            <Row align='right' style={ { maxWidth: '800px', margin: '0 auto' } }>
              <div style={ { marginTop:'15px' } }>
                {!showTasks && !startStudy &&
                  <Button variant="outline-primary" className="btn-sm" onClick={navigateParticipate} style={ { marginRight:'5px' } }>
                    <i className="bi bi-arrow-left"></i>
                  </Button>  
                }
                <Button variant="outline-primary" className="btn-sm" onClick={handleShowTasks}>
                  {showTasks ? 
                    <>
                      <i className="bi bi-arrow-left"></i> Back to Research Scope
                    </>
                      :
                    <>
                      Go to Study Outline and Tasks
                    </>
                  }
                </Button>
              </div>
            </Row>  
            }
            {startStudy &&
            <Row align='right' style={ { maxWidth: '800px', margin: '0 auto' } }>
              <div style={ { marginTop:'15px' } }>
                <Button variant="outline-primary" className="btn-sm" onClick={handleStartStudy}>
                  <i className="bi bi-arrow-left"></i> Back to Study Outline and Tasks
                </Button>
              </div>
            </Row>
            }
            {/* Outline and Scope */}
            {showTasks && !startStudy &&
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>
                    Study Outline and Tasks
                </h3>
                <div style={ { maxWidth: '800px', margin: '0 auto' } }>
                    <Tasks handleStartStudy={handleStartStudy} />                                   
                </div>  
            </Row>
            }

            {/* Start Study */}
            {startStudy &&
              <div style={ { Width:'500px' } }>
              <Row align='center' className="justify-content-center mb-4">
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Let's go !</h3>
                <Col md={8}>
                  <Card className="shadow-lg p-4">

                    {/* Display user data and anonymization reassurance */}
                    <div className="text-center mb-4">
                      <Alert variant="info" style={{ fontSize: '1rem', maxWidth: '700px', margin: '0 auto' }}>
                        <i className="bi bi-shield-lock" style={{ marginRight: '8px' }}></i>
                        Please note that your identifying information will be completely <strong>anonymized</strong>. However, certain information (such as role, expertise, and birth year) will be used for analysis but will not be linked to your identity.
                      </Alert>
                    </div>

                    {/* Display user data and anonymization */}
                    <Table bordered hover responsive className="mb-4" style={{ maxWidth: '700px', margin: '0 auto' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>Plain Data (not used)</th>
                          <th style={{ textAlign: 'center' }}>Data used for Analysis</th>
                          <th style={{ width:'30%', textAlign: 'center' }}>Anonymized Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ padding: '20px' }}>
                            <p><strong>Name:</strong> {expertData.participantName}</p>
                            <p><strong>Email:</strong> {user.email}</p>
                            <p><strong>Consent Given:</strong> {expertData.consentGiven ? 'Yes' : 'No'}</p>
                          </td>
                          <td style={{ padding: '20px' }}>
                            <p><strong>Year of Birth:</strong> {expertData.yearOfBirth}</p>
                            <p><strong>Role:</strong> {expertData.role}</p>
                            <p><strong>Country:</strong> {expertData.country}</p>
                            <p><strong>City:</strong> {expertData.city}</p>
                            <p><strong>Field of Expertise:</strong> {expertData.expertField}</p>
                            <p><strong>Years of Experience:</strong> {expertData.experience}</p>
                          </td>
                          <td style={{ padding: '20px', textAlign: 'center' }}>
                            <div className="anonymized-code-box p-1 bg-light border rounded" style={{ fontSize: '1.2rem' }}>
                              <span style={ { fontSize:'8px', margin:'0px', padding:'0px' } }>Participant Code</span>
                              <br />
                              <strong>{expertData.anonymous_id}</strong>
                            </div>

                            <div className="anonymized-code-box p-1 bg-light border rounded" style={{ fontSize: '1.2rem', marginTop:'15px' }}>
                              <span style={ { fontSize:'8px', margin:'0px', padding:'0px' } }>Date</span>
                              <br />
                              <strong>{expertData.date}</strong>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/* Buttons for Starting Study */}
                    <div className="d-flex justify-content-center">
                      <Button variant="outline-secondary" style={{ marginRight: '5px' }} onClick={() => setStartStudy(false)}>
                        <i className="bi bi-arrow-left-circle"></i>
                      </Button>

                      <OverlayTrigger placement="top" overlay={<Tooltip>Start expert session.</Tooltip>}>
                        <Button variant="success" style={{ marginLeft: '10px' }} onClick={navigateCreate}>
                            <i className="bi bi-rocket" style={{ marginRight: '8px' }}></i>
                            Start Study
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Card>
                </Col>
              </Row>
              </div>
            }
        </Container>
    );
};

export { default as Instructions } from './Instructions';