/**
 * Analytics Adoption
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-21
 * 
 */

import { Pie, Bar, Line } from 'react-chartjs-2';

/**
 * User Adoption Rate Chart
 * Compares the latest entry in the history data with the very first one.
 * Fields adopted from copilot (source === 'ucm_copilot') are considered adopted.
 * Modified fields (source === 'manually') are considered modified.
 * @param {*} param0 
 * @returns 
 */
export const UserAdoptionChart = ({ useCaseData, handleChartClick, handleChartHover }) => {
    const fields = [
        'title', 
        'acronym', 
        'maturity', 
        'application', 
        'status',
        'tags',
        'description', 
        'actions',
        'conditions',
        'actors',
        'components',
    ];

    // Get the first and latest history entries
    const firstHistory = useCaseData.history?.data?.[0] || {};
    const latestHistory = useCaseData.history?.data?.slice(-1)[0] || {};

    // Calculate the number of adopted and modified fields by comparing the first and latest entries
    let adopted = 0;
    let modified = 0;

    fields.forEach(field => {
        const firstSource = firstHistory[field]?.source;
        const latestSource = latestHistory[field]?.source;

        // If the field started as co-pilot and remained co-pilot, it's adopted
        if (firstSource === 'ucm_copilot' && latestSource === 'ucm_copilot') {
            adopted++;
        } else if (firstSource === 'ucm_copilot' && (latestSource === 'manually' || latestSource === 'manual')) {
            // If it started as co-pilot but was manually modified later, it's modified
            modified++;
        }
    });

    // Prepare data for the chart
    const data = {
      labels: ['Adopted from UCM Copilot', 'Manually Modified'],
      datasets: [
        {
          data: [adopted, modified],
          backgroundColor: ['#36A2EB', '#FF6384'],
        },
      ],
    };

    return <Pie 
        data={data} 
        options={{
            onClick: (evt, elems) => handleChartClick(evt, elems, data, 'adoption'),
            onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
    />;
};

/**
 * Adaptation Rate (From First History to Manual Changes)
 * Fields that were modified between first and second history (modified === true)
 * @param {*} param0
 * @returns
 */
export const AdaptationRateChart = ({ useCaseData, handleChartClick, handleChartHover }) => {
    const fields = [
        'title', 
        'acronym', 
        'maturity', 
        'application', 
        'status',
        'tags',
        'description', 
        'actions',
        'conditions',
        'actors',
        'components',
    ];

    // Get the first and latest history entries
    const firstHistory = useCaseData.history?.data?.[0] || {};
    const latestHistory = useCaseData.history?.data?.slice(-1)[0] || {};

    // Track the number of fields where the user changed the initial suggestion
    const modifiedFields = fields.map(field => {
        const firstValue = firstHistory[field]?.value;
        const latestValue = latestHistory[field]?.value;

        return firstValue !== latestValue ? 1 : 0;
    });

    //const totalChanges = modifiedFields.reduce((acc, curr) => acc + curr, 0);

    const data = {
      labels: fields,
      datasets: [
        {
          label: 'Field Modification Count',
          data: modifiedFields,
          backgroundColor: '#FF6384',
        },
      ],
    };

    return <Bar 
        data={data} 
        options={{
            onClick: (evt, elems) => handleChartClick(evt, elems, data, 'adaptation'),
            onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
    />;
};

/**
 * Adaptation Over Time Chart and across all history entries of the use case in question
 * @param {*} param0 
 * @returns 
 */
export const AdaptationOverTimeChart = ({ useCaseData }) => {
    const fields = [
        'title', 
        'acronym', 
        'maturity', 
        'application', 
        'status',
        'tags',
        'description', 
        'actions',
        'conditions',
        'actors',
        'components',
    ];

    // Get all history entries and the timestamps
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    // Track the number of field modifications between consecutive history objects
    const modificationCounts = historyData.map((history, index) => {
        if (index === 0) return 0;  // Skip the first entry, as there's nothing to compare it with

        const previousHistory = historyData[index - 1];

        // Count the number of fields that have changed compared to the previous entry
        return fields.reduce((count, field) => {
            const previousValue = previousHistory[field]?.value;
            const currentValue = history[field]?.value;
            return previousValue !== currentValue ? count + 1 : count;
        }, 0);
    });

    const data = {
      labels: timestamps.map(ts => new Date(ts).toLocaleDateString()),  // Format the timestamps for x-axis
      datasets: [
        {
          label: 'Number of Field Modifications Over Time',
          data: modificationCounts,
          fill: false,
          backgroundColor: '#36A2EB',
          borderColor: '#36A2EB',
          tension: 0.1,  // Optional: make the line smooth
        },
      ],
    };

    const options = {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Timestamp',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Number of Modifications',
          },
          beginAtZero: true,
        },
      },
    };

    return <Line data={data} options={options} />;
};

/**
 * Statistics for Each Step (Description, Actions, Actors, Components)
 * Compare how many fields were modified in each step of the use case modeling
 * Modified fields are fields where modified === true
 * @param {*} param0
 * @returns
 */
export const StepwiseAdoptionChart = ({ useCaseData }) => {
    const steps = {
        description: ['description'],
        actions: ['actions'],
        actors: ['actors'],
        components: ['components'],
    };

    // Get the first and latest history entries
    const firstHistory = useCaseData.history?.data?.[0] || {};
    const latestHistory = useCaseData.history?.data?.slice(-1)[0] || {};

    // Calculate the adoption rate for each step
    const stepAdoption = Object.keys(steps).map(step => {
        const fields = steps[step];
        const totalFields = fields.length;

        const adoptedFields = fields.filter(field => {
            const firstSource = firstHistory[field]?.source;
            const latestSource = latestHistory[field]?.source;
            return firstSource === 'ucm_copilot' && latestSource === 'ucm_copilot';
        }).length;

        return (adoptedFields / totalFields) * 100;
    });

    const data = {
      labels: Object.keys(steps),
      datasets: [
        {
          label: 'Adoption Rate (%)',
          data: stepAdoption,
          backgroundColor: '#36A2EB',
        },
      ],
    };

    return <Bar data={data} />;
};

/**
 * Shows the timeline of changes and updates, such as when new versions were created and how long each version persisted.
 * @param {*} param0 
 * @returns 
 */
export const VersionHistoryTimeline = ({ useCaseData }) => {
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    // Create labels for each version
    const labels = historyData.map((_, idx) => `Version ${idx + 1}`);

    // Format the timestamps to be displayed on the y-axis (e.g., "YYYY-MM-DD HH:mm")
    const formattedTimestamps = timestamps.map(ts => {
        const date = new Date(ts);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    });

    const data = {
        labels, // Version labels (Version 1, Version 2, etc.)
        datasets: [
            {
                label: 'Version History',
                data: Array(timestamps.length).fill(1), // Fill with 1 for each version, since we only need the timeline
                backgroundColor: '#4BC0C0',
                borderColor: '#4BC0C0',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                type: 'category',  // Ensure the y-axis is categorical (display formatted dates)
                labels: formattedTimestamps, // Show formatted dates as y-axis labels
                title: {
                    display: true,
                    text: 'Version Timestamps',
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};


/**
 * Visualizes the proportion of different actors or components involved in the use case, showing how the elements are distributed.
 * @param {*} param0 
 * @returns 
 */
export const ActorInvolvementDistribution = ({ useCaseData }) => {
    const actors = useCaseData.actors?.value?.nodes || [];
    const actorGroups = actors.value.reduce((acc, actor) => {
        const group = actor.group || 'Unknown';
        acc[group] = (acc[group] || 0) + 1;
        return acc;
    }, {});

    const data = {
        labels: Object.keys(actorGroups),
        datasets: [
            {
                data: Object.values(actorGroups),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            },
        ],
    };

    return <Pie data={data} />;
};

/**
 * Assess how complex the use case is based on the number of components.
 * @param {*} param0 
 * @returns 
 */
export const ComponentComplexityChart = ({ useCaseData }) => {
    const components = useCaseData.components?.value || [];
    
    // Loop through each category to count the total number of items within each component category
    const componentCategories = components.map(category => category.category);
    const componentItemCounts = components.map(category => {
        return category.components.reduce((total, component) => {
            return total + (component.items?.length || 0);
        }, 0);  // Sum the item counts for each category
    });

    const data = {
        labels: componentCategories,  // Component categories (e.g., Operations, Producer, etc.)
        datasets: [
            {
                label: 'Number of Component Items',
                data: componentItemCounts,  // Count of component items in each category
                backgroundColor: '#FF6384',
            },
        ],
    };

    return <Bar data={data} />;
};

/**
 * Show the trend of how much the user relies on the co-pilot’s suggestions versus manual input over time.
 * @param {*} param0 
 * @returns 
 */
export const UserEditingBehaviorChart = ({ useCaseData }) => {
    const fields = ['title', 'acronym', 'maturity', 'application', 'status', 'tags', 'description', 'actions', 'conditions', 'actors', 'components'];
    const historyData = useCaseData.history?.data || [];
    const timestamps = useCaseData.history?.time_stamp || [];

    const copilotSuggestions = historyData.map((history) => {
        return fields.filter(field => history[field]?.source === 'ucm_copilot').length;
    });

    const manualChanges = historyData.map((history) => {
        return fields.filter(field => history[field]?.source === 'manually').length;
    });

    const data = {
        labels: timestamps.map(ts => new Date(ts).toLocaleDateString()),
        datasets: [
            {
                label: 'Co-pilot Suggestions',
                data: copilotSuggestions,
                backgroundColor: '#36A2EB',
            },
            {
                label: 'Manual Changes',
                data: manualChanges,
                backgroundColor: '#FF6384',
            },
        ],
    };

    return <Line data={data} />;
};
