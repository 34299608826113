/**
 * Generalized Model Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-06
 * 
 */

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import NetworkAnalysis from './eco_model_network';

const GeneralizedModel = ({ usecases, handleClear }) => {

    return (
        <div>
            <Row>
                <Col md={12} style={{ textAlign: 'left' }} id="content">
                    {/* Title */}
                    <Row style={ { marginBottom:'20px', marginTop:'20px'} }>        
                        <Col>
                        <span>Generalized Autonomous Mobility System Model in the Platform Economy</span>
                        </Col>          
                        <Col className="d-flex justify-content-end">                  
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                        </Col>   
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {/* Generalized Model Network */}
                    <NetworkAnalysis useCases={usecases} />
                </Col>
            </Row>
        </div>
    );
}

export default GeneralizedModel;
